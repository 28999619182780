<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card p-fluid">
				<TabView>
					<TabPanel>
						<Toast />
						<template #header>
							<i class="pi pi-folder p-mr-2"></i>
							<span>Общая информация</span>
						</template>

						<div class="p-field">
							<label for="code">Артикул</label>
							<InputText id="code" v-model.trim="product.code" required="true" autofocus :class="{'p-invalid': submitted && !product.code}" />
							<small class="p-invalid" v-if="submitted && !product.code">Обязательное поле.</small>
						</div>
						<div class="p-field">
							<label for="name">Название</label>
							<InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
							<small class="p-invalid" v-if="submitted && !product.name">Обязательное поле.</small>
						</div>
						
						<div class="p-field">
							<label for="description">Описание товара</label>
							<Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
						</div>

						<div class="p-field">
							<label for="factory">Производитель</label>
							<InputText id="factory" v-model.trim="product.factory" required="true" autofocus :class="{'p-invalid': submitted && !product.factory}" />
							<small class="p-invalid" v-if="submitted && !product.factory">Обязательное поле.</small>
						</div>

						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="price">Цена</label>
								<InputNumber id="price" v-model="product.price" required="true" mode="currency" currency="UAH" locale="en-US" />
							</div>
							<div class="p-field p-col">
								<label for="quantity">Количество</label>
								<InputNumber id="quantity" v-model="product.quantity" integeronly />
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="size">Объем</label>
								<InputNumber showButtons buttonLayout="horizontal" :step="0.05" min = '0.5' 
								decrementButtonClass="p-button-danger" required="true" incrementButtonClass="p-button-success" 
								incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" id="size" v-model="product.size" 
								suffix="л."/>
							</div>
							<div class="p-field p-col">
								<label for="strong">Крепость</label>
								<InputNumber id="strong" required="true" v-model="product.strong" suffix="об."/>
							</div>
						</div>
					</TabPanel>
					<TabPanel >
						<template #header>
							<i class="pi pi-image p-mr-2"></i>
							<span>Картинки</span>							
						</template>
						
						<FileUpload mode="basic" ref = "file" accept="image/*" :maxFileSize="10000000" v-on:change="onUpload"  chooseLabel="Выбрать файл для загрузки" />

            <Carousel :value="images" :numVisible="1" :numScroll="1" :circular="false">
                <template #header>
                </template>
                <template #item="slotProps">
                    <div class="product-item">
                        <div class="product-item-content">
                            <div class="p-mb-3 slider-item">
                                <img :src="slotProps.data.url" alt="" class="product-image" />
								<div class="car-buttons p-mt-5">
                                    <Button v-if="slotProps.data.fileImage == product.image" 
										icon="pi pi-check" class="p-button-rounded p-button-success p-mr-2"
										v-tooltip.top="'Изображение выбрано'"
										@click="changeImage(slotProps.data.url, slotProps.data.fileImage)"/>
									<Button icon="pi pi-check" class="p-button p-button-rounded p-mr-2"
										v-if="slotProps.data.fileImage != product.image"
										v-tooltip.top="'Выбрать изображение'"
										@click="changeImage(slotProps.data.url, slotProps.data.fileImage)"/>	
									<Button icon="pi pi-times" class="p-button-rounded p-button-danger" 
										v-tooltip.top="'Удалить изображение'" 
										@click="deleteImage(slotProps.data.fileImage)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Carousel>


					</TabPanel>
					<TabPanel>
						<template #header>
							<i class="pi pi-folder-open p-mr-2"></i>
							<span>Категории</span>							
						</template>						
						

						<div class="p-grid">
							<div class="p-col-12">
							<div class="card">
								<TreeTable
								:value="productsCategories"
								selectionMode="checkbox" 
								v-model:selectionKeys="selectedCategories"
								:expandedKeys="expandedKeys"
								@nodeSelect="onNodeSelect" 
								@nodeUnselect="onNodeUnselect"
								>
								<!-- <template #header>
									<Button
									label="Сохранить"
									icon="pi pi-save"
									class="p-button-success p-mr-2"
									@click="saveCategory"
									/>
								</template>-->

								<Column field="name" header="Название" :expander="true">
									<!-- <template #body="slotProps">
													<span class="p-column-title">Name</span>
													{{slotProps.node.data.name}}
												</template> -->
								</Column>
								<Column field="description" header="Описание"></Column>
								</TreeTable>

							</div>
							</div>
						</div>

					</TabPanel>
					<TabPanel>
						<template #header>
							<i class="pi pi-globe p-mr-2"></i>
							<span>SEO</span>							
						</template>	
						<div class="p-field p-col">	
						<label for="seoUrl">Ссылка</label>
						<InputText id="seoUrl" type="text" v-model="product.seo.url" />
						</div>

						<div class="p-field p-col">
						<label for="seoKeywords">Ключевые слова</label>
						<InputText id="seoKeywords" type="text" v-model="product.seo.keywords" />
						</div>

						<div class="p-field p-col">
						<label for="seoTitle">Заголовок</label>
						<InputText id="seoTitle" type="text" v-model="product.seo.title" />
						</div>

						<div class="p-field p-col">
						<label for="seoDescription">Описание</label>
						<InputText id="seoDescription" type="text" v-model="product.seo.description" />
						</div>

						<div class="p-field p-col">
						<label for="seoTags">Теги</label>
						<InputText id="seoTags" type="text" v-model="product.seo.tags" />
						</div>

						<div class="p-field p-col">
						<div class="p-text-left p-mb-2">Опубиковать</div>
						<ToggleButton v-model="product.seo.publish" onIcon="pi pi-check" offIcon="pi pi-times" />
						</div>

					</TabPanel>
					<TabPanel>
						<template #header>
							<i class="pi pi-circle-off p-mr-2"></i>
							<span>Дополнительно</span>							
						</template>							
						кастомные хотелки
					</TabPanel>					
				</TabView>							
				<div class="p-d-flex p-mt-2">
					<div class="p-mr-4">
						<router-link to = "/products">
						<Button label="Сохранить" icon="pi pi-check" class="p-button-success" @click="saveProduct" />
						</router-link>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProductService from '../service/ProductService';
import CategoriesService from "../service/CategoriesService";
import ImageService from "../service/ImageService";
//import Galleria from 'primevue/galleria';
//import toast from 'primevue/toast';
// import ImageFromFirebase from '../components/ImageFromFirebase';



export default {
	data() {
		return {
			product_id: '',
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {
				seo: {

				}
			},
			expandedRows: [],
			selectedProducts: null,
			filters: {},
			submitted: false,
			lastSelectedImage: null,
			productsCategories: null,
			selectedCategories: null,
			categories: [],
			expandedKeys: {},
			images: null,
		}
	},
	productService: null,
	categoriesService: null,
	imageService: null,
	created() {
		this.productService = new ProductService();
		this.categoriesService = new CategoriesService();
		this.imageService = new ImageService();
	},
	async mounted() {
		this.product_id = this.$route.params.id;
		if(this.product_id){
			await this.productService.getProduct(this.product_id).then((data) => {this.product = data; 
			this.selectedCategories = this.product.categories
			for (let cat of this.product.categories){
				this.selectedCategories[cat] = {checked: true}
			}});
			
		} else {			
			this.product_id = await this.productService.getProductID(); 
		}
		await this.makeGaleryFormat();
		console.log('Product ID: ' + this.product_id);
		this.categoriesService
		.getCategories()
		.then((data) => {this.productsCategories = data; this.expandAll();});

	},
	methods: {
		async makeGaleryFormat(){
			this.images = [];
			console.log('this.product.images ' + this.product.images);
			if(!this.product.images){
				return;
			}	
			
			for(let p of this.product.images){

				var url = await this.imageService.getImageUrl(this.product.id, p);
				
				//console.log(p + " URL " + url);
				this.images.push({url: url, fileImage: p});

				//console.log(this.images);

			}
			console.log(this.images);
		},
        expandAll() {

            for (let node of this.productsCategories) {
                this.expandNode(node);
            }

            this.expandedKeys = {...this.expandedKeys};
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
			//console.log(node.key);
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },		
        onNodeSelect(node) {
			node.key;
            //this.toast.add({severity:'success', summary: 'Node Selected', detail: node.data.name, life: 3000});
			//this.selectedCategories = {...this.selectedCategories};
			const target_copy = Object.assign({}, this.selectedCategories);
			console.log(target_copy)
			this.categories = []
			for (let cat in target_copy) {
				console.log(cat)
				if (this.selectedCategories[cat].checked) {
					this.categories.push(cat)
				}
			}
			
        },
        onNodeUnselect(node) {
            //this.toast.add({severity:'success', summary: 'Node Unselected', detail: node.data.name, life: 3000});
			console.log(node.key)
        },	
		async onUpload() {
			console.log(this.$refs.file.files[0]);
			await this.imageService.uploadImage(this.product_id,this.$refs.file.files[0]);
			this.product.images = await this.imageService.getImages(this.product_id);
			this.makeGaleryFormat()
			this.$refs.file.files = [];
			console.log('up image')
        },
		
		changeImage(imageUrl, fileImage){
			this.product.image_url = imageUrl;
			this.product.image = fileImage;
			this.$toast.add({severity:'info', summary: 'Изображение изменено!', detail: fileImage, life: 3000});
						
		},

		async deleteImage(fileImage){
			await this.imageService.deleteImage(this.product.id, fileImage);
			this.product.images = await this.imageService.getImages(this.product_id);
			this.makeGaleryFormat();
			console.log('delete image');
			this.$toast.add({severity:'error', summary: 'Изображение удалено!', detail: fileImage, life: 3000});
		},
			
		hideDialog() {
			// this.productDialog = false;
			this.submitted = false;
		},
		async saveProduct() {
			this.submitted = true;
			if (this.product.name.trim()) {
				//this.product.image = 'product-placeholder.svg';
				this.product.categories = this.categories;
				this.product.images = [];
				this.product.images = await  this.imageService.getImages(this.product.id);
				//this.product.image = "";
				if(this.product.images.length > 0){
					if (this.product.image == '') {
						this.product.image = this.product.images[0]
					}
					this.product.image_url = await this.imageService.getImageUrl(this.product.id, this.product.image);
				}

				await this.productService.setProduct(this.product);
				//this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated with ID: ' + id, life: 3000});

				//this.productDialog = false;
				this.product = {};
			}
		},
	
	},
	components: {
		// 'image-from-firebase': ImageFromFirebase
	}
}
</script>

<style scoped>
.product-image {
	max-width: 300px;
}

.slider-item {
	text-align: center;
}

</style>